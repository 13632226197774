<template>
<div>
  <div class="abvvvv">
    <a-row :gutter="16">
      <a-col :span="3" v-for="(stockItem,index) in stockDayLine" :key="index">
          <a-card size="small" @click="clk(stockItem.stock_code)">
            <ve-candle :legend-visible="false" :data="stockItem.stock_chart" :settings="chartStockSettings" :title="{
                          text:`${stockItem.stock_name}`,
                          left: '100',     // 水平位置自动调整
                          bottom: '-10',      // 底部居中
                          textBaseline: 'middle', // 文本垂直居中对齐
                          textAlign: 'center',   // 文本水平居中对齐
                          textStyle: {
                              fontSize: 9.5,
                          }
                }" judge-width width="220px" height="120px"/>
            </a-card>
      </a-col>
    </a-row>
  </div>

  <a-divider>资金动态</a-divider>

  <div class="abvvvv">
    <a-row :gutter="16">
      <a-col :span="12" v-for="(stockLiuru,index) in liuruChart" :key="index">
          <a-card size="small">
            <ve-histogram :data="stockLiuru" 
                  :legend="{show:true,bottom:10}"
                  :grid="{right: '5%'}"
                  :mark-line="markLine"
                  :title="{
                      text:`(${stockLiuru.title?stockLiuru.title:'0'}）`,
                      textStyle: {
                          fontSize: 15,
                      },
                      subtextStyle: {
                          fontSize: 12.5
                      }
                  }"
                  :settings="{
                      label: { show: true, position: 'top'},
                      yAxisType: ['KMB', 'KMB'],
                      showLine: ['占比','涨'], 
                      // axisSite: { right: ['涨'] }, 
                  }" 
                  :extend="histogramChartExtend" judge-width width="920px" height="460px"/>
            </a-card>
      </a-col>
    </a-row>
  </div>

  <a-modal v-model="editVisible" cancelText="取消" okText="查看K线" width="1350px" @ok="handleOk" >
            <vue-element-loading :active="loadingP" color="#00cca2" spinner="spinner"/>
                <ve-histogram 
                :data="predictChart" 
                :legend="{show:true,bottom:10}"
                judge-width
                :grid="{right: '5%'}"
                :mark-line="markLine"
                :title="{
                    text:`(${predictChart.title?predictChart.title:'0'}）`,
                    textStyle: {
                        fontSize: 15,
                    },
                    subtextStyle: {
                        fontSize: 12.5
                    }
                }"
                :settings="{
                    label: { show: true, position: 'top'},
                    yAxisType: ['KMB', 'KMB'], 
                    showLine: ['超大'],
                    // axisSite: { right: ['成交量'] }, 
                }" 
                :extend="histogramChartExtend" ></ve-histogram>

                <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
                    :columns="columns" :dataSource="liuruList">
                    <template slot="index" slot-scope="text, record , index">
                      <span>{{(index+1)}}</span>
                    </template>



                    <template slot="price" slot-scope="text, record , index">
                      <span :style="color(text)"> {{ (formatNumber(text)) }}</span>
                    </template>
                </a-table>

                <template>
                  <a-timeline>
                    <a-timeline-item :color="msg.color" v-for="(msg,index) in predictMsg" :key="index">{{msg.stock_date}} {{msg.tm}} {{msg.msg}}</a-timeline-item>
                  </a-timeline>
              </template>
            <LEmpty v-if="first>0 && predictChart.rows.length == 0"/>
    </a-modal>

</div>
</template>
<script>
const columns = [
  // { title: '#', width:'70px', scopedSlots: { customRender: 'index' }, key: 'id' },
  { title: '日期', align:'center', dataIndex: 'stock_date', key: 'stock_date'},
  { title: '开盘', align:'center', dataIndex: 'stock_jinkai', key: 'stock_jinkai'},
  { title: '价格', align:'center', dataIndex: 'stock_now', key: 'stock_now'},
  { title: '振幅', align:'center', dataIndex: 'stock_zhenfu', key: 'stock_zhenfu'},
  { title: '涨幅', align:'left', dataIndex: 'stock_zhangdiefu', key: 'stock_zhangdiefu', scopedSlots: { customRender: 'price' }},
  { title: '成交额', align:'center', dataIndex: 'stock_chengjiaoe', key: 'stock_chengjiaoe' , scopedSlots: { customRender: 'price' }},
  { title: '净流入',  align:'center', dataIndex: 'stock_liuru', key: 'stock_liuru', scopedSlots: { customRender: 'price' }},
  { title: '流入占比', align:'center', dataIndex: 'stock_liuru_zhanbi', key: 'stock_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '超大流入', align:'center', dataIndex: 'stock_chaoda_liuru', key: 'stock_chaoda_liuru', scopedSlots: { customRender: 'price' }},
  { title: '超大占比', align:'center', dataIndex: 'stock_chaoda_liuru_zhanbi', key: 'stock_chaoda_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '大流入', align:'center', dataIndex: 'stock_da_liuru', key: 'stock_da_liuru', scopedSlots: { customRender: 'price' }},
  { title: '大占比',  align:'center', dataIndex: 'stock_da_liuru_zhanbi', key: 'stock_da_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '中流入', align:'center', dataIndex: 'stock_zhong_liuru', key: 'stock_zhong_liuru', scopedSlots: { customRender: 'price' }},
  { title: '中占比', align:'center', dataIndex: 'stock_zhong_liuru_zhanbi', key: 'stock_zhong_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '小流入', align:'center', dataIndex: 'stock_xiao_liuru', key: 'stock_xiao_liuru', scopedSlots: { customRender: 'price' }},
  { title: '小占比', align:'center', dataIndex: 'stock_xiao_liuru_zhanbi', key: 'stock_xiao_liuru_zhanbi', scopedSlots: { customRender: 'price' }}

] 
  import moment from 'moment'
  import tableMixins from '@/common/mixins/table'
  import statisticsPayment from './leads_follow_count'
  import stockChart from './stock_charts'
  export default {
    name:'stock',
    mixins: [ tableMixins],
    data () {
      this.chartSettings = {
        xAxisType: ['KMB', 'percent'],
      }
      this.markLine = {
          symbol: ['none', 'none'], //去掉箭头
          data: [
          {
              name: '平均线',
              type: 'average'
          }
          ],
          label:{
              position:'insideStartTop'
          }
      },
      this.tooltip = {
          show:true,  
          trigger: "axis",
          alwaysShowContent:true,
          formatter: function(params) { 
            var res;
            console.log(params)
            // if (params.length !== 1) {
            //   res = moment().format("YYYY-MM-DD");
            //   res += "<br/> 时间 : " + params[0].axisValue.split(" ")[1];
            //   res += "<br/> 价格 : " + params[0].value[1];
            //   res += "<br/>  涨跌 : " + params[0].value[2];
            //   res += "<br/>  涨跌幅 : " + params[0].value[3] + "%";
            // } else {
            //   res = moment().format("YYYY-MM-DD");
            //   res += "<br/> 时间 : " + "--";
            //   res += "<br/> 价格 : " + "--";
            //   res += "<br/>  涨跌 : " + "--";
            //   res += "<br/>  涨跌幅 : " + "--";
            // }
            return res;
          }
        },
      this.chartStockSettings = {
        yAxis:{
          show:false,
        },
        grid: {
          containLabel: true,
        },
        showDataZoom: false,
        start: 0,
        end: 100,
        showMA: false,
        MA: [5,10],
        legendName: {}, 
        showVol: false,
        upColor: '#FF8787',
        downColor: '#7FB77E',
      }
      this.markLine = {
          symbol: ['none', 'none'], //去掉箭头
          data: [
          {
              name: '平均线',
              type: 'average'
          }
          ],
          label:{
              position:'insideEndTop'
          }
      },
      this.toolbox = {
          feature: {
          magicType: {},
          saveAsImage: {pixelRatio:2}
          }
      }
      return {
        first:0,
        histogramChartExtend:{
          series(item){
                if(item){
                    item[0].barMaxWidth = 10
                    item[0].itemStyle = Object.assign({}, {
                        normal:{
                            color: function(params) {
                                return {
                                    type: 'linear',
                                    x:1,
                                    y:1,
                                    x2:0,
                                    y2:0,
                                    colorStops: [{
                                        offset: 0,
                                        color: '#ffffff'
                                        
                                    }, {
                                        offset: 1,                    
                                        color: '#23d9ae'
                                    }],
                                }
                            },
                            barBorderRadius:[30,30,0,0]
                        }
                    })
                    item[1].color='red'
                }
                return item
            },
            xAxis:{
                axisLine:{
                    show:true,
                    lineStyle: {
                        type: 'dotted',
                    }
                },
            },
            yAxis(item){
                item[0].axisLine = Object.assign({}, {
                    show:false
                })
                item[0].splitLine = Object.assign({}, {
                    show:true,
                    lineStyle: {
                        type: 'dotted'
                    }
                })
                return item
            },
        },
        columns,
        loading:false,
        stockCode:'',
        predictMsg:[],
        editVisible:false,
        stockChartData:[],
        dailyChart:[],
        predictChart:[],
        loadingP:false,
        liuruChart:[],
        liuruList:[],
        chartSettings:[],
        grid: [],
        stockDayLine:[],
        chartData: {
          columns: ['日期', 'open', 'close', 'lowest', 'highest', 'vol'],
          rows: [
            {
              日期: '01.05', open: 10411.85, close: 10544.07, lowest: 10411.85, highest: 10575.92, vol: 221290000
            }
          ]
        }
      }
    },
    mounted(){
      this.getList();
    },
    unmount () {
    },
    components: {
        stockChart,
        statisticsPayment
    },
    computed: {
    },
    beforeDestroy() {
        this.editVisible = false
    },
    
    methods:{
        color(value){
          if(value < 0){
            return {color:'green'};
          }
          if(value==0){
            return '';
          }
          if(value > 0){
            return {color:'red'};
          }
        },
        formatNumber(value) {
          let flag = '';
          if(value<0){
            value=Math.abs(value)
            flag = '-';
          }
          if (value >= 100000000) {
            return flag+(value / 100000000).toFixed(2) + ' 亿';
          } else if (value >= 10000) {
            return flag+(value / 10000).toFixed(2) + ' 万';
          } else if (value >= 1000) {
            return flag+(value / 1000).toFixed(2) + ' 千';
          } else {
            return flag+value;
          }
        },
        async handleOk() {
          window.open("https://quote.eastmoney.com/"+this.stockCode+".html");
        },
        clk(stock_code){
          this.stockCode = stock_code;
          this.getPredictChart(stock_code)
        },
        async getPredictChart(stock_code) {
              this.editVisible = true;
              this.loadingP = true;
              let res = await this.$store.dispatch('enterpriseLabIndexAction', {data:{search:{stock_name:stock_code}}})
              this.predictChart = res.data.predict_chart
              this.predictMsg = res.data.short_msg
              this.liuruList = res.data.liuru_list
              this.loadingP = false
        },
        async getList() {
            this.loading = true
            let stockChart = await this.$store.dispatch('analysisAceStockAction', {type:this.$route.query.type})
            this.stockChartData = stockChart.data.week_list
            this.liuruChart = stockChart.data.predict_chart
            this.stockDayLine = stockChart.data.stock_day_line
            this.loading = false;
        },
    },
  }
</script>
<style lang="scss">
    /* CSS 样式 */
.echarts-legend {
    display: none;
}
</style>