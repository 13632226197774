var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"cancelText":"取消","okText":"保存","width":"450px"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-card',{attrs:{"title":""}},[_c('div',[_c('vue-element-loading',{attrs:{"active":_vm.loading,"color":"#00cca2","spinner":"spinner"}}),_c('ve-histogram',{attrs:{"data":_vm.leads_data,"legend":{show:true,bottom:10},"judge-width":"","grid":{right: '5%'},"mark-line":_vm.markLine,"title":{
                    text:(_vm.title + "（" + (_vm.leads_data.title?_vm.leads_data.title:'0') + "）"),
                    textStyle: {
                        fontSize: 15,
                    },
                    subtextStyle: {
                        fontSize: 12.5
                    }
                },"toolbox":_vm.toolbox,"settings":{
                    label: { show: true, position: 'top'},
                    yAxisType: ['KMB', 'KMB'],
                    showLine: ['涨幅','占比'],
                    axisSite: { right: ['涨幅','占比'] }, 
                },"extend":_vm.histogramChartExtend}}),(_vm.first>0 && _vm.leads_data.rows.length == 0)?_c('LEmpty'):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }